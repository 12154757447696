<template>
	<div class="home">
		<sidebar
			:step="iCurrStep"
			@changeStep="onChangeStep"
		/>

		<div class="content">
			<div class="content__inner">
				<profile :profile="currProfile" />
			</div>
		</div>
	</div>
</template>

<script>
import Profile from '@/components/Profile.vue';
import Sidebar from '@/components/Sidebar.vue';

export default {
	name: 'home',

	components: {
		'profile': Profile,
		'sidebar': Sidebar
	},

	data: function ()
	{
		return {
			iCurrStep: 1,

			aProfiles: [
				{
					img: require('@/assets/profiles/1.jpg'),
					name: 'Inna',
					age: 32,
					country: 'Ukraine',
					city: '-',
					height: '1.70 м. (5\' 7″)',
					eyes_color: 'green',
					about: 'From the very start I should say that it is not an easy thing to speak about myself as it is hard to have a look at yourself from aside,but at the same time who knows you better than yourself? ...'
				},
				{
					img: require('@/assets/profiles/2.jpg'),
					name: 'Viktoria',
					age: 34,
					country: 'Russia',
					city: 'Krasnodar',
					height: '1.70 м. (5\' 7″)',
					eyes_color: 'brown',
					about: 'Each of us has dreams and I am no exception. My dream is to find a real man who will be loyal and devoted to our future family, will love me and our children. Feelings is the main value in our life , they cannot be ...'
				},
				{
					img: require('@/assets/profiles/3.jpg'),
					name: 'Anastasiya',
					age: '19',
					country: 'Ukraine',
					city: 'Kiev',
					height: '1.65 м. (5\' 5″)',
					eyes_color: 'blue',
					about: 'I am a person who sees the cup always half full! In our life there is no time for sadness or resentment, so I try not to pay attention to temporary adversity. I\'m also a person with a good sense of hum...'
				},
				{
					img: require('@/assets/profiles/4.jpg'),
					name: 'JuliettKisssss',
					age: '31',
					country: 'Ukraine',
					city: 'Odessa',
					height: '1.65 м. (5\' 5″)',
					eyes_color: 'green',
					about: 'As to the character, I am a very easy-going person; I like to chat, to get to know new people and to make friends. Sometimes I can be calm and romantic, sometimes a little devil wake up in me and I ...'
				},
				{
					img: require('@/assets/profiles/5.jpg'),
					name: 'ImagineMe',
					age: '29',
					country: 'Ukraine',
					city: '-',
					height: '1.71 м. (5\' 7″)',
					eyes_color: 'green',
					about: 'Ukrainian girls are known for their ability to sincerely love and care about beloved one. I am not an exception. I will certainly give all of me to my future husband. But the other shade of me is my sexuality ...'
				}
			]
		}
	},

	computed: {
		currProfile: function ()
		{
			var index = Math.floor(Math.random() * 5);

			return this.aProfiles[index];
		}
	},

	methods: {
		onChangeStep: function ()
		{
			this.iCurrStep++;
		}
	}
}
</script>

<style lang="scss">
@import '../styles/style';

.home
{
	position: relative;
	overflow: hidden;
	height: 100vh;
}

.content
{
	height: 100%;
	background: url('~@/assets/bg.jpg') 0 50% repeat-x;
	// background-size: cover;
	overflow: hidden;

	

	&__inner
	{
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 15px;
		height: 100%;
		text-align: left;
	}

	&__subtitle
	{
		margin-top: 10px;
		font-size: 2rem;
	}
	
	.custom-btn
	{
		display: block;
		max-width: 300px;
		margin: 35px auto 0;
	}

	@media (min-width: 1200px)
	{
		animation: animatedBackground 40s linear infinite;

		@keyframes animatedBackground {
			from { background-position: 0 50%; }
			to { background-position: -1476px 50%; }
		}
	}
}
</style>
